.PinkCookieSideList {
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  //border: solid 1px black;
  padding: 40px 0;
  z-index: 0;
  transition: 0.3s;
  transform-origin: left;
  font-family: "oswaldRegular", serif;
}

.PinkCookieSideListMove {
  transition: 0.25s cubic-bezier(0.235, -0.310, 0.310, 1.30);

  &:hover {
    transform: translateX(40px);
  }
}

.PinkCookieSideListFixed {
  pointer-events: none !important;

  &:hover {
    transform: translateX(0px) !important;
  }
}

.PinkCookieSideListItemOption {
  height: 80px;
}

.PinkCookieSideListItem {
  .PinkCookieSideListItemOption();
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  box-sizing: border-box;
  // box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // transition: 0.25s cubic-bezier(0.235, -0.310, 0.310, 3.00);
  transition: 0.25s;
  background: #2a4073;
  font-family: "oswaldRegular", serif;

  &:hover {
    // transform: translateY(-10px);
    z-index: 100;
    background: white;
    // background: #de5b95;
    .infoBox {
      .tips {
        opacity: 1;
      }
    }
  }

  .placeHolder {
    position: relative;
    width: 30%;
    height: 100%;
  }

  .infoBox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    z-index: 10;

    span {
      position: relative;
      display: block;
      box-sizing: border-box;
      color: white;
      font-family: "oswaldRegular", serif;
      transform: scaleY(1.05);
      text-shadow: 0 0 5px rgba(0, 0, 0, 1);
      letter-spacing: 0.9px;
    }

    .title {

    }

    .tips {
      opacity: 0;
      color: #f7fcfe;
      //text-shadow: 0  0 5px white;
    }
  }

  .iconBox {
    position: absolute;
    right: 20px;
    background-size: 100% !important;
    background-position: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
}