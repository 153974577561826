
.main {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 33vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: red;
  transition: 0.5s;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  white-space: nowrap !important;
  transform: translateX(0%) !important;
  z-index: 100;
}

.mainHidden {
  transform: translateX(-100%) !important;
  opacity: 0 !important;
}

.mainPage {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: sans-serif;
  color: white;
  box-sizing: border-box;
  padding-top: 40px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap !important;

  h1 {
    margin-top: 10%;
    position: relative;
    font-size: 1.3rem;
    font-weight: 200;
    letter-spacing: 0.2px;
    display: block;
    flex: 1;
  }

  .sentence {
    position: relative;
    letter-spacing: 0.3px;
    font-size: 1rem;
    display: block;
    flex: 1;
  }
}

.sayPosition {
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.signInBox {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 0 15%;
  margin-bottom: 45%;

  .tips {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20%;

    span {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      font-size: 1.3rem;
      color: rgb(88, 218, 254);
      letter-spacing: 0.7px;
      transform: scaleY(1.2);
    }
  }

  .signInItems {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;

    .signInSpan {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      font-weight: 900;
      letter-spacing: 0.7px;
      transform: scaleY(1.2);
      padding: 10px;
    }

    .item {
      width: 100%;
    }

    .inputItem {
      @leftPadding: 10px;
      @bottomPadding: 10px;

      position: relative;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 10px 5px @bottomPadding @leftPadding;

      .inputTips {
        position: relative;
        font-size: 1rem;
        letter-spacing: 0.9px;
        flex: 1;
      }

      input {
        position: relative;
        flex: 1;
        width: 100%;
        margin-top: 10px;
        outline: none;
        background: transparent;
        border: none;
        border-bottom: solid 2px white;
        color: white;
        display: block;
      }

      .inputItemCheck {
        position: absolute;
        bottom: @bottomPadding;
        left: @leftPadding;
        right: 5px;
        transition: 0.3s;
        transform-origin: left;
        height: 2px;
        width: 100%;
        box-sizing: border-box;
        background: #00cec9;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }
    }

    .optionItem {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      box-sizing: border-box;

      .optionCheck {
        position: relative;
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(235, 116, 139);
        background: transparent;
        box-sizing: border-box;
        margin-right: 10px;

        &:active {
          background: rgb(235, 116, 139);
        }
      }

      .optionTips {
        position: relative;
        letter-spacing: 1px;
        flex: 1;
        transform: scaleY(1.05);
      }

    }

    .buttonItem {
      position: relative;
      width: 90%;
      padding: 10px 0 10px 10px;
      box-sizing: border-box;

      .buttonTips {
        position: relative;
        display: block;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        font-size: 1.2rem;
        letter-spacing: 1.2px;
        background: rgb(34, 151, 185);
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
      }
    }
  }

}

.backBtn {
  position: absolute;
  bottom: 10%;
  left: 0;
  height: 40px;
  width: auto;
  border-radius: 0 3px 3px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
  transform: translateX(-15px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    .backIcon {
      background: rgb(187, 17, 119);
      animation: backIconHover 0.4s 1 running cubic-bezier(0.250, 0.250, 0.000, 0.990) forwards;
    }
  }

  .backIcon {
    position: relative;
    height: 100%;
    width: 50px;
    background: rgb(237, 51, 152);
    transform: skewX(-15deg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    box-sizing: border-box;

    img {
      pointer-events: none;
      transform: skewX(15deg);
    }
  }

  .backText {
    position: relative;
    color: white;
    height: 100%;
    width: 60px;
    transform: skewX(-15deg);
    background: rgb(237, 51, 152);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    &:before {
      content: "back";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 7px;
      left: 36px;
      display: inline-block;
      font-size: 1rem;
      font-weight: 100;
      transform: translateX(-22px) skewX(15deg) scaleY(1.1);
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }
  }
}

@keyframes backIconHover {
  0% {
    width: 50px;
  }
  20% {
    width: 74px;
  }
  40% {
    width: 62px;
  }
  60% {
    width: 70px;
  }
  80% {
    width: 64px;
  }
  100% {
    width: 68px;
  }
}