@import "./blogTop.module.less";
@import "./blogBottom.module.less";
@import "./blogList.module.less";
@import "../../static/css/iconfont.css";

.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  color: white;
  background: rgba(22, 160, 133, 1.0);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.tinyCode {
  position: relative;
  display: inline-block;
  font-size: 0.8rem;
  box-sizing: border-box;
  border-radius: 3px;
  margin-bottom: -5px;
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.2s ease-in-out;
  padding: 0 10px;
  line-height: 20px;
  //box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: rgba(86, 95, 96, 0.7);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 110%;
    width: 200px;
    background-image: linear-gradient(0deg,
    rgba(0, 0, 0, 0),
    rgba(255, 234, 167, 0.3),
    rgba(0, 0, 0, 0));
    transform: rotateZ(45deg) translate(-80px, 80px);
  }

  //&:hover {
  //  transform: translateY(-5px);
  //  box-shadow: 0 5px 10px rgba(255, 234, 167, 0.1);
  //  border-color: transparent;
  //
  //  &::after {
  //    transition: 1s ease-in-out;
  //    transform: rotateZ(45deg) translate(100px, -100px);
  //  }
  //}
}

.blogMarkdownBox {
  position: absolute;
  left: 0;
  // top: @blogListBoxTop;
  // height: calc(100% ~"-" @bottomBoxHeight ~"-" @blogListBoxTop);
  // width: calc(100% ~"-" @blogListBoxWidth);
  height: auto;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  padding: 110px 50px;
  color: rgba(223, 230, 233, 1.0);
  overflow: auto;

  h1, h2, h3, h4, p, ul, ol, blockquote {
    position: relative;
    margin: 25px 0;
    line-height: 1.5rem;
  }

  h1, h2, h3 {
    &::before {
      margin-right: 8px;
      color: #3dc1d3;
    }
  }

  h1 {
    &::before {
      content: "#";
    }
  }

  h2 {
    &::before {
      content: "##";
      background-image: linear-gradient(to right, #00cec9 50%,#00cec9 50%, #00b894 50%,#00b894 90%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text; /* For Chrome, Safari */
      background-clip: text; /* For Future */
    }
  }

  h3 {
    &::before {
      content: "###";
       background-image: linear-gradient(to right, #00cec9 30%,#00cec9 30%,#00b894 30%, #00b894 70%, #e17055 70%,#e17055 90%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text; /* For Chrome, Safari */
      background-clip: text; /* For Future */
    }
  }

  ul, ol {
    margin-left: 20px;

    li {
      margin-top: 5px;

      code {
        .tinyCode();
      }
    }
  }

  strong {
    color: rgb(245, 192, 180);
  }

  p {
    code {
      .tinyCode();
    }
  }

  img {
    display: block;
    max-width: 100%;
    background: white;
    margin: 20px 0;
    box-sizing: border-box;
    border: 2px solid white;
    border-radius: 5px;
    opacity: 0.7;
    transition: 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  table {
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: block;

    thead {
      border-bottom: 2px solid rgba(223, 230, 233, 1.0);
    }

    td {
      padding-left: 15px;
    }
  }

  del {
    position: relative;
    background: black;
    color: black;

    &:hover {
      color: rgba(223, 230, 233, 1.0);
    }
  }

  a {
    position: relative;
    text-decoration: none;
    color: rgba(116, 185, 255, 1.0);

    &:hover {
      &::after {
        transform: rotateZ(360deg);
      }
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 0;
      height: 2px;
      width: 100%;
      background: rgba(9, 132, 227, 1.0);
      box-shadow: 0 0 5px rgba(9, 132, 227, 1.0), 0 0 20px white;
    }

    &::after {
      content: "↗";
      display: inline-block;
      font-size: 1.2rem;
      font-weight: 900;
      transition: 0.5s;
    }
  }

  blockquote {
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 15px;
    margin-top: 10px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: rgba(129, 236, 236, 1.0);
      box-shadow: 8px 0 5px rgba(129, 236, 236, .5), 0 10px 10px white;
    }
  }
}

.codeBox {
  position: relative;
  margin: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  pre {
    background: rgba(17, 27, 39, 0.7) !important;
    border-radius: 0 0 5px 5px;
    transition: 0.2s !important;
    margin-top: 0 !important;

    * {
      font-family: "sourceCodeProRegular", serif !important;
    }

    font-size: 0.8rem;

    &::-webkit-scrollbar {
      width: 0 !important
    }

    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  .infoBox {
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 15px 20px;
    background: rgba(17, 27, 39, 0.7) !important;
    border-radius: 5px 5px 0 0;
    border-bottom: solid 2px rgba(223, 230, 233, 0.3);

    .copyBtn {
      position: relative;
      z-index: 20;
      user-select: none;
      margin-right: 20px;

      .iconfont {
        font-size: 1.5rem;
        z-index: 20;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
        width: 110%;
        transform: translateX(-5%);
        background: rgba(223, 230, 233, 1.0);
        border-radius: 2px;
        transition: 0.3s cubic-bezier(0.235, -0.410, 0.410, 1.400);
        transform-origin: bottom;
        z-index: -1;
      }

      &:hover {
        .iconfont {
          color: rgba(99, 110, 114, 1.0);
        }

        &::before {
          height: 120%;
          background: rgba(253, 203, 110, 1.0);
          box-shadow: 0 0 5px rgba(253, 203, 110, .8), 0 0 10px white;
        }
      }
    }

    .lang {
      position: relative;
      font-weight: 900;
      font-size: 1rem;
      letter-spacing: 0.7px;
      color: rgba(223, 230, 233, 1.0);
      user-select: none;
    }
  }


  &:hover pre {
    background: rgba(17, 27, 39, 0.9) !important;
    box-shadow: 0 0 10px black;
  }
}