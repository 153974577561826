@bottomBoxHeight: 70px;
.TButtom {
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: @bottomBoxHeight;
  background: rgba(0, 0, 0, 0.85);
  z-index: 100;
}