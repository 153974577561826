@import "./blogTop.module.less";
@import "./blogBottom.module.less";
@import "../../static/css/iconfont.css";

.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@blogListBoxTop: @height - @borderRightBoxHeight;
@blogListBoxWidth: 500px;
.blogListBox {
  position: relative;
  top: 10px;
  right: 0;
  height: 100%;
  width: @blogListBoxWidth;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  box-sizing: border-box;

  .itemBox {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .item {
    @picWidth: 120px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    padding-top: 5px;
    padding-left: @picWidth + 10px;
    background: rgba(226, 79, 157, 0.8);
    margin-top: 15px;
    box-sizing: border-box;
    color: white;
    transition: 0.2s cubic-bezier(0.235, -0.410, 0.410, 1.5);
    border-radius: 5px 0 0 5px;

    .thumbnailBox {
      position: absolute;
      left: 0;
      top: 0;
      width: @picWidth;
      height: 100%;
      user-select: none;
      pointer-events: none;
      opacity: 0.8;

      img, div {
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        object-fit: cover;
        border-radius: 5px 0 0 5px;
      }
    }

    .normalTextBox {
      position: relative;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 5px;

      .iconfont {
        font-size: 1.2rem;
        margin-right: 5px;
      }

      .textSpan {
        position: relative;
        letter-spacing: 1.3px;
      }

      &.titleBox {
        font-size: 1rem;
        letter-spacing: 0.7px;
      }
    }

    .ulBox {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 5px;
      box-sizing: border-box;

      .liBox {
        position: relative;
        margin-right: 10px;
        font-size: 0.9rem;
        font-weight: 900;
        display: flex;

        .iconfont {
          margin-right: 5px;
          text-align: center;
          line-height: 23px;
        }

        a {
          color: white;
          text-decoration: none;
        }

        span {
          margin-left: 5px;
        }
      }
    }
  }

  .item:hover {
    .itemHover();
  }

  .itemHover {
    background: rgba(255, 255, 255, 0.8);
    color: rgba(45, 52, 54, 1.0);
    width: 95%;
    margin-bottom: 25px;
    //box-shadow: 0 0 5px white;

    .thumbnailBox {
      opacity: 1;
    }

    .titleBox {
      font-weight: 900;
    }

    .ulBox {
      .liBox {
        a {
          color: rgba(45, 52, 54, 1.0);
          text-decoration: none;
        }
      }
    }
  }

  .headBox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    box-sizing: border-box;
    //margin-bottom: 80px;
    max-width: 100%;
    padding: 10px 15px;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background-color: rgba(253, 121, 168, 1.0);
      transform: rotateY(180deg);
      clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px), 0 0);
      -webkit-clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px), 0 0);
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgb(33, 24, 33);
      transform: rotateY(180deg);
      clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px), 0 0);
      -webkit-clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 16px, 100% 100%, 16px 100%, 0 calc(100% - 16px), 0 0);
    }

    .head {
      position: relative;
      box-sizing: border-box;
      padding: 3px 20px;
      margin: 5px 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: row;
      color: rgba(255, 255, 255, 0.7);
      z-index: 2;
      cursor: pointer;
      user-select: none;

      &:hover {
        filter: contrast(1.1);
      }


      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -1px;
        left: 100%;
        right: -1px;
        bottom: -1px;
        background-color: rgb(199, 73, 122);
        clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 8px, 100% 100%, 8px 100%, 0 calc(100% - 8px), 0 0);
        -webkit-clip-path: polygon(0 0, calc(100% - 8px) 0, 100% 8px, 100% 100%, 8px 100%, 0 calc(100% - 8px), 0 0);
      }

      &::after {
        content: "";
        position: absolute;
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
        border: 1.5px solid rgb(229, 94, 147);
        opacity: 0;
        border-radius: 0 15px 0 15px;
      }

      &:hover {
        &::before {
          left: -1px;
        }

        &::after {
          animation: clipPathHead 2s infinite linear;
          opacity: 1;
        }
      }

      .indexSpan {
        position: relative;
        font-size: 10px;
        height: 20px;
        //line-height: 25px;
        text-align: center;
        display: inline-block;
        box-sizing: border-box;

        &::after {
          content: "";
          position: relative;
          height: 100%;
          width: 1px;
          background: rgba(255, 255, 255, 0.6);
          display: inline-block;
          box-sizing: border-box;
          margin: 0 5px;
        }
      }

      .titleSpan {
        position: relative;
        letter-spacing: 0.9px;
        height: 100%;

      }
    }

    .headHere {
      filter: contrast(1.1);

      &::before {
        left: -1px !important;
      }

      &::after {
        animation: clipPathHead 2s infinite linear;
        opacity: 1;
      }
    }
  }
}

@keyframes clipPathHead {
  0%,
  100% {
    clip-path: inset(0 0 98% 0);
  }

  25% {
    clip-path: inset(0 98% 0 0);
  }
  50% {
    clip-path: inset(98% 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 98%);
  }
}
