.bigR0Box {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 110;
  pointer-events: none;
  .R0Dynamic {
    position: relative;
    width: 85%;
  }

  .R0Fixed {
    position: relative;
    width: 85%;
    opacity: 0.3;
    .ringFixedJitterAnimation();
  }
}

.AnimationAlways1s {
  animation-duration: 1s;
  animation-play-state: running;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
}

.cookieJitterAnimation {
  animation-name: cookieJitter;
  .AnimationAlways1s();
}

.ringDynamicVagueAnimation {
  animation-name: ringDynamicVague;
  .AnimationAlways1s();
}

.ringFixedJitterAnimation {
  animation-name: ringFixedJitter;
  .AnimationAlways1s();
  animation-duration: 0.9s;
}

.ringDynamicFineLightDiffusionAnimation {
  animation-name: ringDynamicFineLightDiffusion;
  .AnimationAlways1s();
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}

.pinkCookie {
  position: fixed;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: url("../../static/cursor/help.cur"), pointer !important;
  pointer-events: auto;

  &:hover {
    transition: 0.3s;
    transform: scale(1.1);
  }

  .ring {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 50%;
    background: white;
  }

  .cookieDynamic {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .cookieJitterAnimation();

    canvas {
      position: absolute;
      left: 0px;
      display: inline-block;
      box-sizing: border-box;
      border-radius: 50%;
    }

    .ringDynamic {
      .ring();
      width: 100%;
      height: 100%;
      .ringDynamicVagueAnimation();
    }

    .ringDynamicInsideShadow {
      position: relative;
      background-color: transparent;
      border-radius: 50%;
      pointer-events: none;
    }

  }

  .ringFixed {
    .ring();
    opacity: 0.5;
    .ringFixedJitterAnimation();
    z-index: 100;
  }

  .ringDynamicFineLight {
    .ring();
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: inset 0 0 20px 0 rgba(255, 255, 255, 0.15);
    .ringDynamicFineLightDiffusionAnimation() ;
    pointer-events: none;
  }

}

@keyframes cookieJitter {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ringDynamicVague {
  0% {
    background-color: white;
  }
  10% {
    background-color: rgba(249, 234, 241, 1);
  }
  100% {
    background-color: white;
  }
}

@keyframes ringFixedJitter {
  0% {
    transform: scale(1);
  }
  9% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ringDynamicFineLightDiffusion {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  10% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}