.templatePage {
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
}

.mainContent {
  flex: 1;
  width: 100%;
}