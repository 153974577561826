.win10GridBox {
  position: relative;
  width: 100%;
  border: solid 2px white;
  box-sizing: border-box;
  color: white;
}
.mainBox {
  position: relative;
  width: 100%;

  min-height: 200px;
  box-sizing: border-box;
}