@showWidth: 100px;
@showHeight: 100px;
@duration: 0.8s cubic-bezier(0.235, -0.310, 0.310, 2.30);
.showR0 {
  position: relative;
  width: @showWidth;
  height: @showHeight+30px;
  z-index: 100;
  cursor: url("../../static/cursor/help.cur"), pointer !important;
  pointer-events: auto;
  &:hover {
    .head, .headShadow {
      transition-delay: 0.8s;
      bottom: -5px;
      opacity: 1;

      img {
        -webkit-filter: grayscale(0); /* Chrome, Safari, Opera */
        filter: grayscale(0);
      }
    }

    .triangle:nth-child(1) {
      transition-delay: 0.3s;
      transform: rotateZ(360deg) translateY(44%) translateX(-30px);
      z-index: -2;

      .text {
        // letter-spacing: 3rem ;
        transition: 0.3s;
        transform: translateX(100%);
        opacity: 0;
      }

      .background {
        opacity: 1;
        background-image: linear-gradient(to right, #a0d8ef, #ec6d51);
      }
    }

    .triangle:nth-child(2) {
      transition-delay: 0.3s;
      transform: translateY(30px) translateX(20px) rotateZ(20deg);

      .background {
        opacity: 1;
        background-image: linear-gradient(to right, #a0d8ef, #ec6d51);
      }
    }

    .frame:nth-child(3) {
      transition-delay: 0.3s;
      transform: rotateZ(210deg) translateY(-20px) translateX(10px);
      opacity: 1;

      .background {
        background-image: linear-gradient(to right, rgb(247, 119, 61), rgb(251, 223, 211));
      }

      .outline {
      @include coverer;
        transition-delay: 0.8s;
        fill: transparent;
        stroke: #b5f4f4;
        stroke-dasharray: 50 50;
        stroke-dashoffset: -75;
      }
    }

    .loading {
      top: 40px;
      transition-delay: 0.8s;
      opacity: 0.9;
    }

    .radiusBox {
      transition: 0.8s cubic-bezier(0.235, -0.310, 0.310, 1.30);
      transition-delay: 1s;
      transform: rotateZ(0deg);
      opacity: 1;
    }

    .trapezoid {
      opacity: 1;
      transition-delay: 0.3s;
      height: @showWidth*1.3;
      width: @showHeight*1.3;
      transform: rotateZ(-20deg);
      right: -60px;
      bottom: 0;

      .background {
        background-image: linear-gradient(to right, #a0d8ef, #ec6d51);
      }

      .xkd(@n, @i: 1) when (@i =< @n) {
        fieldset:nth-of-type(@{i}) {
          transform: rotateZ((183deg/@n*@i - 85deg)) scaleX(1);
          transition: 0.2s;
          transition-delay: (0.2s*@i)+1.5s;
        }
        .xkd(@n, (@i + 1));
      }
      .xkd(4);
    }
  }
}

.triangle, .frame, .radiusBox, .trapezoid {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 0;
  box-sizing: border-box;
  transition: @duration;

  .background {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: @duration;
    background: #fdeff2;
  }

  .text {
    color: white;
    white-space: nowrap;
  }

  &:nth-child(1) {
    transform: rotateZ(20deg) translateY(45%);
    transform-origin: 100% 56%;

    .background {
      opacity: 0.5;
      clip-path: polygon(100% 56%, 0 38%, 65% 100%);
    }

    .text {
      display: block;
      position: absolute;
      bottom: 57px;
      opacity: 0.6;
      transform: rotateZ(10deg) translateX(10px);
    }
  }

  &:nth-child(2) {
    transform: translateY(100px) translateX(-40px) rotateZ(180deg);
    z-index: 2;

    .background {
      clip-path: polygon(100% 0, 0 83%, 78% 100%);
      background: #fdeff2;
      opacity: 0.5;

      &::after {
        position: absolute;
        content: "";
        height: 6px;
        width: 130px;
        background: white;
        box-shadow: 0 0 15px #15eded;
        top: 39px;
        left: -15px;
        transform: rotateZ(-40deg);
        filter: blur(1px);
      }
    }
  }
}

.frame {
  width: 130px;
  height: 130px;

  .outline {
    stroke: #ffcc00;
    stroke-width: 4px;
    fill: transparent;
    stroke-dasharray: 50 200;
    stroke-dashoffset: 1875;
    transition: 1s;
  }

  .background {
    width: 90%;
    height: 90%;
    left: 5%;
    bottom: 5%;
  }

  &:hover {

  }

  &:nth-child(3) {
    transform: rotateZ(30deg) translateY(105px) translateX(50px);
    opacity: 0.2;

    .background {
      clip-path: polygon(0% 0%, 0% 100%, 5% 100%, 4% 4%, 96% 4%, 96% 96%, 4% 96%, 5% 100%, 100% 100%, 100% 0%);
    }
  }
}

.head, .headShadow {
  position: absolute;
  bottom: -50px;
  left: -10px;
  width: @showWidth+10px;
  height: @showHeight;
  z-index: -1;
  opacity: 0.2;
  transition: 0.7s;

  img {
    position: relative;
    width: 100%;
    object-fit: cover;
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
  }
}

.headShadow {
  bottom: -40px;
  transform: scaleZ(1.3);
  filter: blur(10px) brightness(80%) opacity(.8);
}

.loading {
  position: absolute;
  top: 110%;
  right: -10px;
  display: block;
  width: 100px;
  height: 100px;
  text-align: center;
  filter: contrast(1.2);
  z-index: -2;
  transition: 1s;
  opacity: 0.3;

  span {
    mix-blend-mode: screen;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: wave 3s infinite linear;
  }

  span:nth-child(0) {
    left: -11%;
    right: -2%;
    top: -12%;
    bottom: -5%;
    transform-origin: 46% 53%;
    animation-delay: 0s;
    background-color: #e17055;
  }

  span:nth-child(1) {
    left: -4%;
    right: -4%;
    top: -9%;
    bottom: -2%;
    transform-origin: 47% 50%;
    animation-delay: -1s;
    background-color: #fdcb6e;
  }

  span:nth-child(2) {
    left: -11%;
    right: -4%;
    top: -10%;
    bottom: -11%;
    transform-origin: 49% 53%;
    animation-delay: -1.5s;
    background-color: #00b894;
  }

  span:nth-child(3) {
    left: -7%;
    right: -9%;
    top: -11%;
    bottom: -4%;
    transform-origin: 47% 52%;
    animation-delay: -2s;
    background-color: #55efc4;
  }

  span:nth-child(4) {
    left: -8%;
    right: -3%;
    top: -5%;
    bottom: -11%;
    transform-origin: 47% 52%;
    animation-delay: -2.5s;
    background-color: #81ecec;
  }

  span:nth-child(5) {
    left: -10%;
    right: -8%;
    top: -4%;
    bottom: -9%;
    transform-origin: 48% 51%;
    animation-delay: -3s;
    background-color: #00cec9;
  }

  span:nth-child(6) {
    left: -9%;
    right: -11%;
    top: -5%;
    bottom: -8%;
    transform-origin: 47% 50%;
    animation-delay: -3.5s;
    background-color: #d63031;
  }
}

@keyframes wave {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.radiusBox {
  width: 150px;
  height: 130px;
  z-index: -3;
  right: -50px;
  top: 30px;
  transform: rotateZ(170deg);
  transform-origin: 0 100%;
  transition: @duration;
  opacity: 0.2;

  .background {
    background: white;
    border-radius: 30% 70% 70% 30% / 30% 24% 76% 70%;
  }
}

.trapezoid {
  display: flex;
  align-items: center;
  justify-content: center;
  right: -20px;
  bottom: -60px;
  opacity: 0.2;
  transition: @duration;
  z-index: -2;


  &:nth-child(6) {
    .background {
      clip-path: polygon(0 81%, 100% 44%, 76% 100%, 0% 100%);
      background: #fdeff2;
    }
  }
}

fieldset {
  position: absolute;
  top: -30%;
  left: -20%;
  width: 100px;
  height: 100px;
  border-top: 2px solid #fdcb6e;
  box-sizing: border-box;
}

legend {
  font-size: 0.8rem;
  line-height: 10px;
  font-weight: bold;
  margin: auto;
  padding: 2px 10px;
  box-sizing: border-box;
}

.gOctagon {
  fieldset {
    border-top-color: white;
    transform-origin: center 123%;
    display: inline-block;
    border-width: 2px 0 0 0;

    legend {
      color: white;
    }
  }

  .xkd(@n, @i: 1) when (@i =< @n) {
    fieldset:nth-of-type(@{i}) {
      transform: rotateZ((200deg/@n*@i - 90deg)) scaleX(0);
      transition-delay: 0.2s*@i;
      transition: 0.2s;
    }
    .xkd(@n, (@i + 1));
  }
  .xkd(4);
}