@import "../../static/css/iconfont.css";

.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.picDetail {
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@boxShadow: 0 0 20px rgba(129, 236, 236, 0.2),
0 0 50px rgba(225, 241, 241, 0.3);
.picDetailMainBoxVertical {
  aspect-ratio: 9 / 16 !important;
}

.picDetailMainBox {
  @picDetailMainBoxRadius: 10px;
  position: relative;
  width: 90%;
  height: auto;
  max-width: 1200px;
  max-height: 80vh;
  aspect-ratio: 16 / 9;
  // background: #eefafb;
  border-radius: 0 @picDetailMainBoxRadius @picDetailMainBoxRadius @picDetailMainBoxRadius;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: @boxShadow;

  .optBox {
    position: absolute;
    bottom: 100%;
    left: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: @boxShadow;
    border-radius: @picDetailMainBoxRadius @picDetailMainBoxRadius 0 0;
    transition: 0.2s;

    @indexItemWidth: 5rem;

    .indexItem {
      background: rgba(0, 0, 0, 0.8);
      //width: @indexItemWidth;
      position: relative;
      padding: 5px 10px 6px 10px;
      color: white;
      box-sizing: border-box;
      line-height: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        position: relative;
        z-index: 100;
      }
    }

    .optItem {
      background: rgba(0, 0, 0, 0.8);
      position: relative;
      padding: 5px 10px 5px 10px;
      font-size: 15px;
      color: white;
      box-sizing: border-box;
      cursor: url("../../static/cursor/pointer.cur"), pointer !important;
      line-height: 22px;

      &::before {
        content: "";
        background: linear-gradient(-45deg, rgba(238, 119, 82, 0.8), rgba(231, 60, 126, 0.8), rgba(35, 166, 213, 0.8), rgba(35, 213, 171, 0.8));
        background-size: 400% 400%;
        animation: Gradient 3s ease infinite;
        animation-play-state: running;
        width: 100%;
        height: 0;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        box-sizing: border-box;
        transition: 0.1s;
      }

      &:hover {
        background: white;
        color: white;

        &::before {
          height: calc(100% ~"-" 5px);
        }
      }

      .iconfont {
        position: relative;
        width: 100%;
        height: 100%;
        display: inline-block;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 22px;
      }

      .iconfontRotate90 {
        font-size: 16px;
      }

      &:nth-child(1) {
        border-top-left-radius: @picDetailMainBoxRadius;
      }

      &:last-child {
        border-top-right-radius: @picDetailMainBoxRadius;
      }
    }
  }

  .imgBox {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0 @picDetailMainBoxRadius 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    box-sizing: border-box;

    img {
      position: relative;
      display: block;
      background: transparent;
      //user-select: none;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoBox {
    position: absolute;
    width: 100%;
    top: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0 0 @picDetailMainBoxRadius 0;
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;
    word-wrap: break-word;
    word-break: normal;
    color: white;
    transition: 0.2s;
    box-shadow: @boxShadow;

    .nameBox {
      position: relative;
      display: block;
      width: 100%;
      top: 100%;
      z-index: 10;
      box-sizing: border-box;
      word-wrap: break-word;
      word-break: normal;
      letter-spacing: 1.5px;
    }

    .modifiedTimeBox {
      position: absolute;
      top: 100%;
      left: 0px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 0 0 @picDetailMainBoxRadius @picDetailMainBoxRadius;
      padding: 0px 10px 5px 10px;
      font-size: 12px;
      //box-shadow: @boxShadow;
      box-sizing: border-box;
    }


    &::before {
      content: "";
      background: linear-gradient(-45deg, rgba(238, 119, 82, 0.8), rgba(231, 60, 126, 0.8), rgba(35, 166, 213, 0.8), rgba(35, 213, 171, 0.8));
      background-size: 400% 400%;
      animation: Gradient 3s ease infinite;
      animation-play-state: running;
      width: 100%;
      height: 5px;
      display: block;
      position: absolute;
      left: 0;
      top: 0px;
      box-sizing: border-box;
      transition: 0.2s;
      z-index: 0;
      border-radius: @picDetailMainBoxRadius;
    }

    &:hover {
    }
  }
}

.picDetailBgMask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
