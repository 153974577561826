@import "../../static/css/iconfont.css";

.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  position: relative;
  color: #b2bec3;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  .directionIcon {
    display: inline-block;
    //margin-right: 15px;
    font-size: 40px;
    margin: 3px -5px;
    font-weight: 300;
    transition: 0.3s;

    &:hover {
      color: #fdcb6e;
    }
  }

  .downDirectionIcon {
    transform: rotateZ(180deg);
  }

  .tips {
    position: relative;
    font-size: 2rem;
    transform: scaleY(0.9);
    // font-weight: 700;

  }

  .paramBox {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    box-sizing: border-box;
    padding: 3px 10px;
    border-radius: 5px;
    border: solid #727a7c 1px;
    margin-right: 10px;

    .option {
      position: relative;

      .checkedText {
        position: relative;
        text-align: center;
      }

      .select {
        position: relative;
        box-sizing: border-box;
        transition: 0.2s;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

      }

      .selectHidden {
        height: 0 !important;
        overflow: hidden;
      }
    }

    .paramTexts {
      position: relative;
    }

    .paramSortDirections {
      position: relative;
    }
  }
}