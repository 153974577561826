@bottomBoxHeight: 120px;
.blogBottomBox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: @bottomBoxHeight;
  background: rgba(0, 0, 0, .7);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  .extraButtonList {
    position: relative;
  }
}

// 导航栏
.navigationBar {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: -3px;
  right: 450px;
  height: 123px;

  .navigation {
    @nWidth: 150px ;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: @nWidth;
    border-radius: 2px;
    box-shadow: inset 0 0 7px rgba(255, 255, 255, 0.7);
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;
    transition: 0.2s;

    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      box-sizing: border-box;
      box-shadow: 0 5px 10px white;
      filter: blur(1px);
      border-radius: 4px;
      transition: 0.3s;
    }

    .nTitle {
      position: absolute;
      bottom: 13px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 1.4rem;
      letter-spacing: 2px;
      color: white;
      font-family: "oswaldLight", serif;
    }

    .nIconBox {
      position: relative;
      flex: 1;
      box-sizing: border-box;
      opacity: 0.2;
      transition: 0.3s;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: fill;
        object-position: 0 0;
      }
      .longImg {
         object-position: 0 -30px;
      }
    }

    &:nth-child(1)::after {
      background: #00b894;
    }

    &:nth-child(2)::after {
      background: #e17055;
    }

    &:nth-child(3)::after {
      background: #fdcb6e;
    }

    &:hover {
      .nIconBox {
        opacity: 1;
      }
      .nTitle {
        opacity: 0;
      }

      &:nth-child(1) {
        background: #00b894;

        &::after {
          background: white;
          box-shadow: 0 0 10px #00b894;
        }
      }

      &:nth-child(2) {
        background: #e17055;

        &::after {
          background: white;
          box-shadow: 0 0 10px #e17055;
        }
      }

      &:nth-child(3) {
        background: #fdcb6e;

        &::after {
          background: white;
          box-shadow: 0 0 10px #fdcb6e;
        }
      }

    }
  }
}