
* {
  // cursor: none !important;
  // cursor: url("static/cursor/default.cur"), auto;
}


.show-cursor {
  cursor: auto !important;
}

.App {
  // background: red;

  .globalCanvas {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: inline-block;
    box-sizing: border-box;
    background: transparent !important;
    pointer-events: none;
    z-index: 1000;
    opacity: 0.8;
    filter: brightness(115%) drop-shadow(0px 0px 20px white) blur(1px);
  }
}